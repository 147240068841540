import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useStaticQuery, graphql, navigate } from 'gatsby';
// components
import Goals from '../goals';
import Image from '@components/image/image';
import Loader from '../loader';
import ResultCard from './result-card';
import RecommendedForYou from './RecommendedForYou/RecommendedForYou';
// context
import { ERROR_MESSAGES, LANA_AI_EXAMPLE_QUERIES } from '@src/constants';
// hooks
import useGlobalError from '@hooks/useGlobalError';
// queries
import { DASHBOARD_UNIFIED } from '@queries/user';
// types
import { IRecommendedProduct } from '../../types/product';
import { IDashboardUnifiedQueryResponse } from '../../types/query-response';
import { IDirectusAccountDashboard } from './../../types/directus/account/account-dashboard';
// styles
import styles from './account-dashboard.module.scss';
import PillLoader from '@src/features/account/pages/result/components/PillLoading/PillLoader';
import { ResultPageContext } from '@src/features/account/pages/result/store/store';
import { GlobalContext } from '@src/store/global-state';
import { useFeatureFlags } from '@src/hooks/useFeatureFlags';
import { trackEvent } from '@src/services/tracking/tracking';

const AccountDashboard: React.FC = () => {
  const { directusAccountDashboard } = useStaticQuery<IDirectusAccountDashboard>(graphql`
    query AccountDasboard {
      directusAccountDashboard {
        goals_title
        plans_title
        recommendations_title
        additional_recommendations_title
        no_result_title
        no_result_subtitle
        no_result_options {
          title
          subtitle
          filename_disk
          path
        }
        result_header
        result_items {
          text
          icon
        }
      }
    }
  `);

  // queries
  const { data, loading, error } = useQuery<IDashboardUnifiedQueryResponse>(DASHBOARD_UNIFIED, {
    fetchPolicy: 'network-only',
  });

  const { dataRecommendedProducts, consultationStatusCompleted } = useContext(ResultPageContext);
  const { userKitStatus } = useContext(GlobalContext);
  const { health_goals, user_subscriptions, user_hasAIConversation } = data || {};
  const health_recommendedProducts = dataRecommendedProducts?.health_recommendedProducts;

  const sortRecommendations = (arr: IRecommendedProduct[]) => {
    // This function has to be refactored, it mutates the prop arr instead of creating the new one
    const essentialOneIndex = arr.findIndex(item => item.id.startsWith('essential-one'));
    const essentialOne = essentialOneIndex > -1 ? arr.splice(essentialOneIndex, 1)[0] : null;
    const personalisedIndex = arr.findIndex(item => item.id.startsWith('sn'));
    const personalised = personalisedIndex > -1 ? arr.splice(personalisedIndex, 1)[0] : null;

    if (essentialOne) {
      arr.unshift(essentialOne);
    }

    if (personalised) {
      arr.unshift(personalised);
    }

    return arr;
  };

  useGlobalError(!!error, ERROR_MESSAGES.generic);

  const selectedGoals = health_goals && health_goals.filter(goal => goal.selected === true);
  const topGoal = useMemo(() => {
    if (!selectedGoals || selectedGoals.length === 0) {
      return null;
    }
    return selectedGoals[0];
  }, [selectedGoals]);

  // examples for top goals
  const topGoalExamples = useMemo(() => {
    const goal = topGoal;
    if (!goal) {
      return null;
    }
    const goalExamples: string[] | undefined = (LANA_AI_EXAMPLE_QUERIES as any)[goal.id];
    // return 3 random examples
    if (goalExamples) {
      const shuffled = goalExamples.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 3);
    } else {
      return null;
    }
  }, [topGoal]);

  const subscriptions = user_subscriptions?.filter(sub => sub.status !== 'cancelled');
  const recommendations = health_recommendedProducts
    ? sortRecommendations(health_recommendedProducts)
    : [];

  const openAiUrl = () => {
    var currentHost = window.location.hostname;
    if (currentHost === 'localhost') {
      currentHost = 'dev.vitl.com';
    }
    const url = `//ai.${currentHost}`;
    window.open(url, '_blank');
    trackEvent('dashboard_ai_cta_clicked', {});
  };

  const [ aiCtaEnabled, setAiCtaEnabled ] = useState(false);
  const { getFeatureFlags, dataFeatureFlags } = useFeatureFlags();

  useEffect(() => {
    if (!dataFeatureFlags)  getFeatureFlags();
    setAiCtaEnabled( dataFeatureFlags?.config_featureFlags.includes('lana_ai_cta_dashboard') );
  }, [dataFeatureFlags]);

  if (loading) return <PillLoader />;

  if (user_hasAIConversation && aiCtaEnabled) {
    trackEvent('dashboard_ai_cta_view', {});
  }

  return (
    <>
      {user_hasAIConversation && aiCtaEnabled && (
        <section className={styles.section}>
          <h1 className={styles.h2}>New for you</h1>
          <div className={styles.aiChatAd} onClick={openAiUrl}>
            <div className={styles.aiChatAdTitle}>
              <img
                src="/images/icon_lana_ai.svg"
                alt="LANA AI icon" />
              <h2>Ask LANA</h2>
              <span className={styles.betaTag}>Beta</span>
            </div>
            <h3>Your personal AI Nutritionist</h3>
            <ul className={styles.aiChatAdQuestions}>
              {topGoalExamples && (
                <>
                {topGoalExamples.map((example: string) => (
                  <li><p>{example}</p></li>
                ))}
                </>
              )}
              {!topGoalExamples && (
                <>
                <li><p>How will my pack help me?</p></li>
                <li><p>What foods will support my goals?</p></li>
                <li><p>Which healthy habits can I adopt?</p></li>
                </>
              )}
            </ul>
            <div className={styles.textfieldCTA}>
              Ask me something
            </div>
          </div>
        </section>
      )}

      <section className={styles.section}>
        <div className={styles.container}>
          <h1 className={styles.h2}>{directusAccountDashboard.result_header}</h1>
          <div className={styles.resultWrap}>
            {userKitStatus &&
              directusAccountDashboard?.result_items.map((item: any) => (
                <ResultCard
                  key={item.text}
                  isConsultationCompleted={consultationStatusCompleted}
                  dataUserKitsStatus={userKitStatus}
                  dataResultItem={item}
                />
              ))}
          </div>
        </div>
      </section>

      <section className={styles.header}>
        <div className={styles.container}>
          {selectedGoals && selectedGoals.length > 0 && (
            <div>
              <div className={styles.row}>
                <div className={styles.colLg4}>
                  <h1 className={styles.h2}>{directusAccountDashboard.goals_title}</h1>
                </div>
              </div>
              <Goals type="dashboard" goals={health_goals} />
            </div>
          )}

          {selectedGoals?.length === 0 && (
            <>
              <h1 className={styles.h2}>{directusAccountDashboard.no_result_title}</h1>
              <p className={styles.h5}>
                <strong>{directusAccountDashboard.no_result_subtitle}</strong>
              </p>
              <ul className={styles.row}>
                {directusAccountDashboard.no_result_options.map((item: any, i: number) => (
                  <li className={styles.optionWrapper} key={i}>
                    <div className={styles.option} onClick={() => navigate(item.path)}>
                      <Image filename={item.filename_disk} alt={item.title} width={60} />
                      <h2 className={styles.h3}>{item.title}</h2>
                      <p>{item.subtitle}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </section>

      {subscriptions && subscriptions?.length > 0 && (
        <section className={styles.section}>
          <div className={styles.container}>
            <h2 className={styles.h2}>Your Plans</h2>
            <ul className={styles.panels}>
              {subscriptions?.map(subscription => (
                <li
                  key={subscription.id}
                  className={styles.panel}
                  onClick={() => navigate('/account/subscriptions')}
                >
                  <div className={styles.panelHeader}>
                    <Image src={subscription.product.imageUrl} width={160} />
                  </div>
                  <div className={styles.panelBody}>
                    <h3 className={styles.h5}>{subscription.product.name}</h3>
                    <div className={styles.divider}></div>
                    <p>Status: {subscription.status}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}
      <RecommendedForYou healthRecommendedProducts={recommendations} />
    </>
  );
};

export default AccountDashboard;
